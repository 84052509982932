import Document from "../components/v2/Document";
import Section from "../components/section/Section";
import Title from "../components/Title";

const PrivacyPolicy = () => (
	<Document
		title="Privacy Policy"
		className="page-privacy"
		ogData={{ url: "/privacy-policy" }}
		accentureAccessible={true}
	>
		<Section>
			<div className="row">
				<div className="col">
					<Section className="py-8 py-md-20" smallContainer={true}>
						<Title>Privacy Policy</Title>
						<p className="text-italic text-gray-dark text-right small-text">Last updated: 22nd March 2024</p>
						<h3 id="who-we-are">Who we are</h3>
						<p>
							Stay Nimble is a social enterprise dedicated to helping people realise their employability as the nature
							of work rapidly changes. We support people in understanding their natural abilities and how these can be
							directed to new work in their local economy. Stay Nimble is registered as a limited company (company
							number 11129024).
						</p>
						<h3 id="policy-summary">Policy summary</h3>
						<p>
							Stay Nimble (“we”) promises to respect any personal data you share with us, or our partner organisations,
							and keep it safe. We will be transparent when we collect data and will not do anything you wouldn’t
							reasonably expect.
						</p>
						<p>
							Developing a better understanding of our customers through their personal data allows us to make better
							decisions, support people more efficiently and, ultimately, help people to find work that is financially
							and emotionally rewarding.
						</p>
						<p>
							Our marketing communications include information about our latest work, case studies and the services we
							provide. If you would like to receive such communications but have not opted in, please contact us at{" "}
							<a href="mailto:info@staynimble.co.uk" className="link">
								info@staynimble.co.uk
							</a>{" "}
							or visit{" "}
							<a href="http://www.staynimble.co.uk" className="link">
								www.staynimble.co.uk
							</a>
						</p>
						<h3 id="data-protection">Data Protection</h3>
						<p>
							In carrying out our normal activities, we process and store personal information relating to our customers
							and we are therefore required to adhere to the requirements of the UK General Data Protection Regulation
							(UK GDPR) and the Data Protection Act 2018. We take our responsibilities under these regulations very
							seriously and ensure personal information we obtain is held, used, transferred, and processed in
							accordance with these laws and all other applicable data protection laws and regulations.
						</p>
						<h3 id="the-ways-we-collect-data">The ways we collect data</h3>
						<ol>
							<li>You may give it to us directly</li>
						</ol>
						<p>
							You may give us your information when you sign up to our newsletters, purchase a subscription or an event
							registration, tell us your story, build your profile, or communicate with us. We never use third-party
							agencies to collect personal information.
						</p>
						<ol start="2">
							<li>You may have your details passed on by a contact or family member</li>
						</ol>
						<p>
							A friend or relative of yours may pass on your details to us for a specific purpose. For example, if they
							think you might benefit from our services, they may invite you to join the platform. We will not further
							process your data or contact you without you telling us you would like further contact.
						</p>
						<ol start="3">
							<li>You may give permission for other organisations to share it, or it is available publicly.</li>
						</ol>
						<p>
							We don’t do this yet, but we want to let you know that we are looking at how to make it easier for you to
							build your profile more efficiently. In the future, we may get information from other organisations. This
							information comes from the following sources:
						</p>
						<h5 id="information-available-publicly">Information available publicly</h5>
						<p>
							This may include information found in places such as Companies House or through the electoral roll and
							information that has been published in articles/newspapers.
						</p>
						<ol start="4">
							<li>
								It may be collected when you use our website Like most websites, we use “cookies” to help you use our
								website with ease.
							</li>
						</ol>
						<p>
							Cookies mean that a website will remember you. They’re small text files that sites transfer to your
							computer, phone or tablet. They make interacting with a website faster and easier – for example by
							automatically filling your name and address in text fields.
						</p>
						<p>
							The type of device you’re using to access our website may provide us with information about your device,
							including what type of device it is, what specific device you have, what operating system you’re using,
							what your device settings are, and why a crash has happened. This is to make our website widely accessible
							on a range of devices and operating systems and to help us fix bugs that stop you using our website
							effectively. Your device manufacturer or operating system provider will have more details about what
							information your device makes available to us.
						</p>
						<h3 id="the-data-we-collect-and-how-we-use-it">The data we collect and how we use it</h3>
						<p>
							The type and amount of information we collect (and how we use it) depends on why you are providing it. We
							process this data on the basis of legal bases such as contractual necessity (for providing you with the
							services you have asked for), consent (for marketing communications), and legitimate interests (for
							improving our services).
						</p>
						<p>If you are one of our amazing customers, we will usually collect data that includes:</p>
						<ul>
							<li>Your name</li>
							<li>Your email address</li>
							<li>Your payment, bank or credit card details</li>
							<li>Your current employment status</li>
							<li>The town or city in which you live</li>
							<li>Your work experience and skills</li>
						</ul>
						<p>Your data will mainly be used for:</p>
						<ul>
							<li>Providing you with the services, products or information you have asked for</li>
							<li>Processing any subscriptions you have agreed to</li>
							<li>Asking you to help others in the Stay Nimble community by sharing your story</li>
							<li>Keeping a record of your relationship with us</li>
							<li>Ensuring we know how you prefer to be contacted by us</li>
							<li>Understanding how we can improve our services, products or information</li>
						</ul>
						<p>We may also use your personal information to detect and reduce fraud and credit risk.</p>
						<h3 id="credit-debit-card-payment-information">Credit, debit card payment information</h3>
						<p>
							When you use your credit or debit card to pay for your subscription, we will ensure that this is done
							securely and in accordance with the Payment Card Industry Data Security Standard. Find out more
							information about PCI DSS standards by visiting their website at{" "}
							<a href="http://www.pcisecuritystandards.org" className="link">
								www.pcisecuritystandards.org
							</a>
							.
						</p>
						<p>We do not store your credit or debit card details in full, following the registration of your card.</p>
						<p>
							To process payments made through our website we use a third-party payment merchant called Stripe Payments
							Europe Ltd (“Stripe”), PayPal Ltd (“PayPal”). This is an internationally trusted payment service used by
							millions of online retailers and international businesses and individuals. They have stringent security
							and data processes to protect your data. Stripe and PayPal may use, retain and disclose your personal
							information and credit card details for this purpose and as set out in their privacy policy. In the
							unlikely event of a data breach affecting financial transactions, we will promptly notify affected
							individuals in accordance with our legal obligations. Additionally, some of these transactions may involve
							the transfer of your personal data outside of the UK; we ensure such transfers are carried out securely
							and in line with UK GDPR requirements.
						</p>
						<h3 id="legitimate-interest-and-targeted-communications">
							Legitimate interest and targeted communications
						</h3>
						<p>
							In some instances, we may collect and use personal information where this is necessary in our legitimate
							interest to support you find work. We only ever collect this data within the confines of the law and
							within your rights as an individual. To provide an improved experience for you, we occasionally use
							profiling and screening techniques to ensure communications are relevant and timely. This also allows us
							to target our services effectively; helping you when you need help.
						</p>
						<p>
							When building a profile, we analyse the information you have freely provided to us relating to your
							interest and experiences, in order to better understand your interests and preferences and to contact you
							with the most relevant communications. In doing this, we may use additional information using publicly
							available data about you.
						</p>
						<p>
							In all cases, we balance our legitimate interests against your rights as an individual and make sure we
							only use personal information in a way or for a purpose that you would reasonably expect in accordance
							with this Policy and that does not intrude on your privacy or previously expressed contact preferences.
						</p>
						<h3 id="direct-marketing">Direct marketing</h3>
						<p>
							We would like to keep you updated with our work and show you how being part of the Stay Nimble community
							helps others to succeed in their lives. If you opt in to be contacted, we will contact you using the
							methods (email only) that you have specified. We may include invites to events and may ask for other
							support.
						</p>
						<p>
							We only want to contact you in the way that you like and strive to make it easy for you to tell us how you
							want us to communicate with you. If you do not want to hear from us, just let us know when you provide
							your data, follow the unsubscribe link on emails, or contact us at{" "}
							<a href="mailto:info@staynimble.co.uk" className="link">
								info@staynimble.co.uk
							</a>
							.
						</p>
						<p>We will never sell or share personal details to third parties for the purposes of their marketing.</p>
						<h3 id="partners">Partners</h3>
						<p>
							We do work with carefully selected partners to provide the services. This is because it is the most cost
							effective way of fulfilling these. We only work with partners who comply with all current data protection
							legislation. Any personal information sent to the partner is encrypted and protected to ensure your data
							is safe, and we require all partners to comply with UK GDPR and have appropriate data protection measures
							in place.
						</p>
						<p>
							We may share your email address with partners available on the Stay Nimble platform in order to identify
							any existing accounts you may have on their websites and apps. Partners will not market their services to
							you unless you have signed up for their services and accepted their terms and conditions.
						</p>
						<h3 id="sharing-your-story">Sharing your story</h3>
						<p>
							You may choose to tell us about your experiences of career change, using our services or your direct or
							indirect experience with supporting others with finding new work. This may include you sharing sensitive
							information in addition to your biographical and contact information. This information is always stored
							securely and will never be used without your permission.
						</p>
						<p>
							We may use some of the information provided in your story, including gender, age, or a specific
							experience, to inform our marketing. This is so that those with an interest in a particular area will hear
							about related work.
						</p>
						<p>
							If you have provided your story, and have given explicit and informed consent (or have consented as a
							parent or guardian if the story relates to a person under 18), this information may be made public by us
							in materials promoting our services, or in documents such as our annual report. We will always seek
							permission.
						</p>
						<h3 id="childrens-data">Children’s data</h3>
						<p>
							At times, we may need to collect and manage information about people under the age of 18. Information is
							usually collected when children either sign up to our services, or a parent / guardian signs up on their
							behalf. We will always keep this information secure and we do not knowingly collect personal data for
							marketing purposes from children without parental consent.
						</p>
						<p>
							Anyone of any age is able to sign up on our website and opt in to receive marketing material. If a child
							signs up in this way, we will not be aware of their age. In this circumstance, they may receive marketing
							material. If your child is receiving marketing or fundraising material and you wish this to stop, please
							contact us at{" "}
							<a href="mailto:info@staynimble.co.uk" className="link">
								info@staynimble.co.uk
							</a>
							.
						</p>
						<h3 id="how-we-keep-your-data-safe-and-who-has-access">How we keep your data safe and who has access</h3>
						<p>
							We ensure that there are appropriate controls in place to protect your personal details. For example,
							online forms on our website are always encrypted and our network is protected and routinely monitored.
						</p>
						<p>
							We undertake regular reviews of who has access to information that we hold to ensure that your information
							is only accessible by appropriately trained staff. Anyone with access to personal information must sign a
							confidentiality agreement as part of their contract which, among other things, includes strict regulations
							on the processing of personal data.
						</p>
						<p>
							Where we use external companies, such as partners, to process personal data on our behalf, we undertake
							comprehensive checks on these companies before working with them. We have contracts in place that set out
							expectations and requirements, especially regarding how they manage the personal data they have access to.
						</p>
						<p>
							We may disclose your personal information to third parties if we are required to do so through a legal
							obligation (for example, to the police or a government body); to enable us to enforce or apply our terms
							and conditions or rights under an agreement; or to protect us, for example, in the case of suspected fraud
							or defamation.
						</p>
						<p>
							We will never sell your details to any third parties and do not share personal data with any third parties
							other than in the circumstances already outlined.
						</p>
						<h3 id="your-right-to-know-what-we-know-about-you-make-changes-to-or-ask-us-to-stop-using-your-data">
							Your right to know what we know about you, make changes to, or ask us to stop using your data
						</h3>
						<p>
							You have a right to ask us to stop processing your personal data, and if it’s not necessary for the
							purpose you provided it to us for (for example, processing your subscription or delivering our service to
							you) we will do so. You also have the right to ask us to delete your personal data. Additionally, under
							the UK GDPR, you have the right to access, rectify, erase, restrict processing of, and obtain a copy of
							your personal data in a portable format. To exercise these rights, please contact us at{" "}
							<a href="mailto:info@staynimble.co.uk" className="link">
								info@staynimble.co.uk
							</a>
						</p>
						<p>Please be aware that we will always use the most recent information you provide us.</p>
						<p>
							If at any time you find you have inadvertently opted out of contact, you can re-join the mailing list
							online at{" "}
							<a href="http://www.staynimble.co.uk" className="link">
								www.staynimble.co.uk
							</a>
						</p>
						<p>
							You have a right to ask for a copy of the information we hold about you, although we may charge £10 to
							cover the costs involved. If you spot any discrepancies in the information we provide, please let us know
							and we will correct them. To make a request, send a description of the information you want to see and
							proof of your identity by post to: Data Protection, Stay Nimble, 20 – 22 Wenlock Road, London, N1 7GU. For
							security reasons, we must handle these requests in writing, by post and only when you provide proof of
							identity. This is to ensure your data is secure and we are not sharing it with someone else.
						</p>
						<p>
							If you have any questions, please send them to{" "}
							<a href="mailto:info@staynimble.co.uk" className="link">
								info@staynimble.co.uk
							</a>{" "}
							and for further information, see the Information Commissioner’s guidance on the ICO website.
						</p>
					</Section>
				</div>
			</div>
		</Section>
	</Document>
);

export default PrivacyPolicy;
